<template>
    <CommonLayoutsVue :isLogo="false" layoutContentStyle="margin-top: 3rem;">
        <template v-slot:child>

            <b-row class="Create-vc centered-max-width">
                <div class="Create-vc-header-container">
                    <div class="Create-vc-Heading left">{{ $gettext("Creating_visiting_card") }}</div>
                    <div class="active-label right"> 1/4</div>
                </div>
                <div class="Create-vc-ProgressBar"></div>
                <div style="top: 36px;" class="active-bar w-25">
                </div>
            </b-row>
            <b-row class="centered-container centered-max-width">

                <div class="header-container text-center">
                    <h2 class="centered-header">{{ $gettext("Personal_details") }}</h2>
                </div>

                <b-row class="w-100">
                    <div class="col-12 mb-3 avtar-content">
                        <div v-if="profileUrl === null" class="user-avtar">
                            <label for="user-dp">
                                <img class="user-avtar-img" :src="avtar" alt="" srcset="">
                            </label>
                            <b-form-file @change="uploadProfileImage" style="position: fixed; left: -1000000px;"
                                v-model="profileImage" id="user-dp" accept="image/*"></b-form-file>
                        </div>
                        <label v-else for="user-dp">
                            <b-avatar style=" cursor: pointer;background: #f36e2143;" size="6rem">
                                <img :src="`${IMAGEURL}${profileUrl}`" width="100%" height="auto" class="img-fluid" />
                            </b-avatar>
                            <b-form-file @change="uploadProfileImage" style="position: fixed; left: -1000000px;"
                                v-model="profileImage" id="user-dp" accept="image/*"></b-form-file>
                        </label>
                        <div class="">
                            <h2 class="UploadProfileImage">
                                {{ $gettext("Upload_profile_image") }}
                            </h2>
                            <div class="light-gray-small-text">
                                {{ $gettext("only_PNG_JPG_Allowed_up_To_10MB") }}
                            </div>
                        </div>
                    </div>
                </b-row>
                <b-row class="centered-content">
                    <b-form class="customized-form w-100">
                        <ValidationObserver ref="personal_details" tag="div">
                            <div class="col-12">
                                <ValidationProvider rules="required" name="fullName" v-slot="{ errors, classes }">

                                    <b-form-input :class="classes" id="FullName" v-model="fullName" size="sm"
                                        type="text" placeholder="Full Name"></b-form-input>

                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <b-row class="p-1 px-3">
                                <div class="col-md-6">
                                    <ValidationProvider rules="required" name="age" v-slot="{ errors, classes }">
                                        <v-select placeholder="Age" class="form-control" :class="classes" v-model="age"
                                            :options="ageOption"> </v-select>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-md-6">
                                    <ValidationProvider rules="required" name="gender" v-slot="{ errors, classes }">
                                        <v-select placeholder="Select gender" class="form-control" :class="classes"
                                            v-model="gender" :options="['Female', 'Male', 'Other']"> </v-select>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </b-row>
                            <b-row class="p-1 px-3">
                                <div class="col-md-6">
                                    <ValidationProvider
                                        :rules="email !== null && mobile !== null ? mobile !== null && mobile !== '' ? 'required|Phone' : '' : 'required|Phone'"
                                        v-slot="{ errors, classes }">

                                        <b-form-input :class="classes" id="mobile" v-model="mobile" size="sm"
                                            type="text"
                                            placeholder="Mobile number (eg. 256123456789 or +256123456789)"></b-form-input>

                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? 'Mobile number (eg. 256123456789 or +256123456789)' :
                                                errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-md-6">
                                    <!-- <ValidationProvider name="email|" :rules="mobile !== null ? '' : 'required|Email'"
                                        v-slot="{ errors, classes }"> -->
                                    <ValidationProvider
                                        :rules="mobile !== null && email !== null ? email !== null && email !== '' ? 'required|Email' : '' : 'required|Email'"
                                        v-slot="{ errors, classes }">
                                        <b-form-input :class="classes" id="email" v-model="email" size="sm" type="text"
                                            placeholder="Email"></b-form-input>

                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>

                            </b-row>
                            <b-row class="p-1 px-3">
                                <div class="col-12">
                                    <ValidationProvider rules="required" name="nationality"
                                        v-slot="{ errors, classes }">
                                        <v-select placeholder="Nationality" class="form-control" :class="classes"
                                            v-model="nationality" :options="NationalityOption"
                                            :get-option-label="(option) => option.nationality"> </v-select>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </b-row>
                            <b-row class="p-1 px-3">
                                <div class="col-md-6">
                                    <ValidationProvider rules="required" name="Country" v-slot="{ errors, classes }">
                                        <v-select placeholder="Country" class="form-control" :class="classes"
                                            v-model="country" :options="CountryOption"
                                            @input="GetAllCityOfCountry(country.country_id)"
                                            :get-option-label="(option) => option.country_name">
                                        </v-select>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-md-6">
                                    <ValidationProvider rules="required" name="city" v-slot="{ errors, classes }">
                                        <v-select placeholder="City" class="form-control" :class="classes"
                                            v-model="city" :options="CityOption"> </v-select>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </b-row>
                            <div class="col-12 btn-flex-fd-row-jc-sb">
                                <span @click="goBack" class="go-back">{{ $gettext("Back") }}</span>
                                <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">{{
                                    $gettext("Next") }}</b-button>
                                <!-- <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">
                                    <router-link to="personal-details">Next</router-link>
                                </b-button> -->
                            </div>

                            <!-- <div class="col-12 mb-4">
                                <b-button @click.prevent.stop="ValidateInput" class="btn full-width-btn" variant="primary"
                                    size="sm">Next</b-button>
                            </div> -->
                        </ValidationObserver>
                    </b-form>

                </b-row>
            </b-row>
        </template>
    </CommonLayoutsVue>
</template>

<script>
import CommonLayoutsVue from '../../../components/Layouts/Common-Layouts.vue'
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import avtar from "@/assets/zaajira-candidate/assets/icons/ph_user-bold.svg";
import { IMAGEURL } from "@/config";
export default {
    name: "PersonalDetails",
    components: { CommonLayoutsVue },
    data() {
        return {
            avtar,
            IMAGEURL,
            profileImage: [],
            profileUrl: null,
            fullName: null,
            user_first_name: null,
            user_last_name: null,
            age: null,
            gender: null,
            email: null,
            mobile: null,
            nationality: null,
            country: null,
            city: null,
            // ageOption: [],
            CountryOption: [],
            CityOption: [],
            NationalityOption: [],
        }
    },

    beforeMount() {

        this.PreFilledData()
    },

    methods: {
        goBack() {
            this.$router.push({ path: 'create-visiting-card' })
        },
        async uploadProfileImage(e) {
            console.log('file', e.target.files[0]);
            let form_data = new FormData();
            form_data.append('image', e.target.files[0]);
            let loader = this.$loading.show();
            try {
                const imgResp = await CandidateService.uploadProfileImage(form_data)
                // console.log('imgResp', imgResp);
                // console.log('imgRespMSG', imgResp.data.data.msg);
                // console.log('imgRespPath', imgResp.data.data.path);
                if (imgResp.data.status && imgResp.data.message === 'Image uploaded successfully') {
                    this.profileUrl = imgResp.data.data.path
                    this.$store.dispatch("onSetProfileImage", imgResp.data.data.path);
                    this.$toasted.show(`${imgResp.data.message}`, {
                        duration: 6000,
                        type: "success",
                        icon: "check",
                    });
                    loader.hide();
                }
                else {
                    throw imgResp
                }
            } catch (error) {
                this.$toasted.show(`Image extension not suppoted`, { duration: 6000 });
                loader.hide();
                console.log("error", error);
            }
        },
        removePlus(mobileNumber) {
            return mobileNumber.replace(/\+/g, '');
        },
        ValidateInput() {
            return this.$refs.personal_details.validate().then((result) => {
                if (this.mobile === null && this.email === null || this.mobile === '' && this.email === '') {
                    this.$toasted.show("Mobile number or email is required", {
                        duration: 6000
                    });
                    return
                }
                if (result) {


                    let split_name = this.fullName.split(' ');

                    if (split_name.length == 2 || split_name.length == 3) {
                        this.user_first_name = split_name[0],
                            this.user_last_name = split_name[1];
                    }
                    // console.log('this.nationality', this.nationality);
                    // console.log('this.country', this.country);
                    // console.log('this.city', this.city);

                    const payload = {
                        user_username: this.fullName,
                        first_name: this.user_first_name,
                        last_name: this.user_last_name,
                        age: this.age,
                        gender: this.gender === 'Female' ? 'F' : 'M',
                        email: this.email,
                        mobile_no: this.removePlus(this.mobile),
                        nationality: this.nationality.nationality_id,
                        location: this.country.country_id,
                        city: this.city.city_id,
                        // nationality: this.nationality[0].nationality_id,
                        // location: this.country[0].country_id,
                        // city: this.city[0].city_id,
                    }
                    console.log(payload);
                    let loader = this.$loading.show();
                    CandidateService.updateUser(payload)
                        .then((res) => {
                            const userData = this.$store.getters.getLoggedUser
                            userData.userData.country_id = this.country.country_id
                            this.$store.dispatch("onSetUserSigninValues", userData);
                            console.log(res);
                            console.log('res', res.data.data);
                            console.log('res', res.data.message);
                            this.$toasted.show(`${res.data.message}`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });
                            this.$router.push({ name: "Industry" })
                            loader.hide();
                        })
                        .catch((error) => {
                            console.log(error);
                        })

                }
            })
        },
        async getAllNationality() {
            // MasterServices.GetAllNationality().then((res) => {
            //     this.NationalityOption = res.data.data;
            // });

            try {
                const NationalityRes = await MasterServices.GetAllNationality()
                return NationalityRes.data.data;
            } catch (error) {

            }
        },
        async getAllCountry() {
            // MasterServices.GetAllCountry()
            //     .then((res) => {
            //         console.log("res.data.data", res.data.data);
            //         this.CountryOption = res.data.data.length ? res.data.data : []
            //         console.log("this.CountryOption", this.CountryOption);

            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            try {
                const AllCountryRes = await MasterServices.GetAllCountry()
                return AllCountryRes.data.data
            } catch (error) {
                console.log(error);
            }
        },
        async GetAllCityOfCountry(country_id) {
            console.log("this.country", country_id);
            if (country_id !== null) {
                const payload = {
                    country_id: country_id
                }
                // MasterServices.GetAllCityOfCountry(payload).then((res) => {
                //     console.log('City::', res);
                //     this.CityOption = res.data.data.map((city) => {
                //         return {
                //             label: city.city_name,
                //             city_id: city.city_id,
                //         };
                //     });

                // });
                try {
                    const cityRes = await MasterServices.GetAllCityOfCountry(payload)
                    this.CityOption = cityRes.data.data.map((city) => {
                        return {
                            label: city.city_name,
                            city_id: city.city_id,
                        };
                    });

                } catch (error) {
                    console.log("city::error", error);
                }
            }
        },
        async GetMasterData() {
            this.NationalityOption = await this.getAllNationality()
            this.CountryOption = await this.getAllCountry()

        },
        async PreFilledData() {
            let loader = this.$loading.show();
            await this.GetMasterData()
            // console.log("this.$store.getters.getLoggedUser", this.$store.getters.getLoggedUser);
            const userData = this.$store.getters.getLoggedUser
            CandidateService.getUserByUserID(userData.userId)
                .then(async (res) => {
                    console.log("res>>>", res.data.data[0])
                    await this.GetAllCityOfCountry(res.data.data[0].country_id)
                    this.profileUrl = res.data.data[0].profile_image
                    this.fullName = res.data.data[0].user_username
                    this.age = res.data.data[0].user_age
                    this.gender = res.data.data[0].user_gender
                    this.email = res.data.data[0].user_email
                    this.mobile = res.data.data[0].user_mobile_number
                    this.nationality = this.NationalityOption.filter((n) => n.nationality_id === res.data.data[0].nationality_id)
                    this.country = this.CountryOption.filter((n) => n.country_id === res.data.data[0].country_id)
                    this.city = this.CityOption.filter((n) => n.city_id === res.data.data[0].city_id)
                    loader.hide();
                })
        }
    },
    computed: {
        ageOption() {
            // Generate an array of numbers from 1 to 100
            return Array.from({ length: 54 }, (_, index) => index + 18).map((number) => (number));
        },
    },

}
</script>

<style>
.UploadProfileImage {
    color: black;
    font-size: 14px;
    font-family: var(--font-family-sans-serif);
    font-weight: 600;
    line-height: 21px;
    word-wrap: break-word;
}

.avtar-content {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
}

.centered-max-width {
    width: 100%;
    max-width: 600px;
}

/* @media screen and (max-width:768px) {
    .centered-max-width {
        max-width: 345px;
    }
} */
</style>